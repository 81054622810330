<template>
  <main>
    <v-card flat class="mb-5">
      <v-card-title>
        Offers

        <v-btn icon class="ml-3" @click="getOffers" :disabled="offers.loading"
          ><v-icon>{{ icons.mdiReload }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': offers.loading,
          }"
          item-key="_id.offer"
          :items="offers.list"
          :page="offers.meta.page"
          :server-items-length="offers.meta.total || 0"
          :loading="offers.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.income`]="{item}">
            {{ item.income }} {{item.offer && item.offer.token}} {{item.offer && item.offer.blockchain}}
          </template>

          <template #[`item.budget`]="{item}">
            {{ getOfferBudget(item) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import moment from 'moment'

import { mapState, mapGetters } from 'vuex'
import { mdiInformation, mdiReload, mdiShieldLock, mdiDotsVertical } from '@mdi/js'

export default {
  data: () => ({
    icons: { mdiInformation, mdiReload, mdiDotsVertical, mdiShieldLock },
    offers: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    tableColumns() {
      return [
        { text: 'PROJECT', value: 'project.name', sortable: false },
        { text: 'OFFER', value: 'offer.name', sortable: false },
        { text: 'SALES', value: 'sales', sortable: false },
        { text: 'INCOME', value: 'income', sortable: false },
        { text: 'BUDGET', value: 'budget', sortable: false },
      ]
    },
  },
  methods: {
    getOffers(query) {
      this.offers.loading = true

      const params = {
        limit: this.offers.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/publisher/commissions', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.offers.meta = meta
          this.offers.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.offers.loading = false
          })
        })
    },

    getOfferBudget(item) {
      const budget = item?.offer?.web3data?.budget

      return budget ? `${budget} ${item.offer.token}` : `-`
    },

    changePagination(pagination) {
      this.getOffers({
        page: pagination.page,
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
