var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Offers "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":_vm.offers.loading},on:{"click":_vm.getOffers}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"footer-props":{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': _vm.offers.loading,
        },"item-key":"_id.offer","items":_vm.offers.list,"page":_vm.offers.meta.page,"server-items-length":_vm.offers.meta.total || 0,"loading":_vm.offers.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.income",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.income)+" "+_vm._s(item.offer && item.offer.token)+" "+_vm._s(item.offer && item.offer.blockchain)+" ")]}},{key:"item.budget",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOfferBudget(item))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }